import Vue from "vue";
import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
//import Cloudinary from "cloudinary-vue";
import VueLoading from "@jxstjh/vue-loading-overlay";
import "@jxstjh/vue-loading-overlay/dist/vue-loading.css";
import VueConfirmDialog from "vue-confirm-dialog";
import i18n from "./i18n";
import moment from 'moment';
import Notifications from 'vue-notification'
//import Toaster from "@meforma/vue-toaster";
//import VueI18n from 'vue-i18n'

Vue.use(VueLoading);
Vue.use(VueConfirmDialog);
Vue.component("vue-confirm-dialog", VueConfirmDialog.default);
/*Vue.use(Cloudinary, {
  configuration: { 
    cloudName: "dwedq7j5n",
    secure: true }
});*/

Vue.config.productionTip = false;
Vue.use(CoreuiVue);
Vue.use(moment);
Vue.use(Notifications)
//Vue.use(Toaster);

const langs = ['fr', 'es', 'de', 'en']


let urlParams = new URLSearchParams(window.location.search);

if(urlParams.has('lng') && langs.includes(urlParams.get('lng')))
{
  i18n.locale = urlParams.get('lng')
} 
else
{
  if (localStorage.getItem("lang")) i18n.locale = localStorage.getItem("lang");
  else {
    let language;
    if (navigator.browserLanguage) language = navigator.browserLanguage;
    else language = navigator.language;

    if (language.indexOf("fr") > -1) i18n.locale = "fr";
    else if (language.indexOf("es") > -1) i18n.locale = "es";
    else i18n.locale = "en";
  }
}

store.dispatch("checkL").finally(() => {
  new Vue({
    router,
    store,
    icons,
    
    beforeCreate() {
      this.$store.commit("initializeAuth");
    },

    i18n,
    render: (h) => h(App),
  }).$mount("#app");
});
