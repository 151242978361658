<template>
  <div id="app">
    <router-view/>
    <vue-confirm-dialog></vue-confirm-dialog>
    <notifications group="foo" position="bottom right" />
  </div>
</template>

<script>
export default {
  name: 'App',
  created: function () {
    //this.$store.dispatch('initAuth')
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style> 


