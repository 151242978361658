import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'

// Views - Pages

const Login = () => import('../views/pages/Login.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/dashboard',
    component: () => import('../template/Template.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: 'about',
        name: 'About',
        component: () => import('../views/About.vue')
      },
      {
        path: '/screen/:id',
        name: 'Screen',
        component: () => import('../views/Screen.vue')
      },
      {
        path: '/dashboard/account',
        name: 'Account',
        component: () => import('../views/Account.vue')
      },
      {
        path: '/dashboard/about',
        name: 'About',
        component: () => import('../views/pages/About.vue')
      },
      {
        path: '/dashboard/setting',
        name: 'Setting',
        component: () => import('../views/Setting.vue')
      },
      {
        path: '/dashboard/gallery',
        name: 'Gallery',
        component: () => import('../views/GaleriePage.vue')
      },
      {
        path: '/dashboard/agency',
        name: 'Agency',
        component: () => import('../views/Agency.vue')
      },
      {
        path: '/dashboard',
        name: 'Dashboard',
        component: () => import('../views/Dashboard.vue')
      }
    ]
  },
  {
    path: '/pages',
    name: 'Pages',
    component: {
      render (c) { return c('router-view') }
    },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: Login
      }
    ]
  }
]

const router = new VueRouter({
 // mode: 'history',
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    console.log('check auth and go login YYYYYYYYYYY')
    //if (!store.getters.isLoggedIn) {
      //next({ name: 'Login' })
   /*/ } else {
      next() // go to wherever I'm going
    }*/
      
      if(!store.getters.isLoggedIn)
        next({ name: 'Login' })
      else
        next()
  } else {
    console.log('dont need auth')
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
